var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Registration - step 3 of 5 ")
      ]),
      _c("gov-back-link", { attrs: { to: { name: "register-new-step2" } } }, [
        _vm._v(" Back ")
      ]),
      _c("information-copy"),
      _c(
        "gov-button",
        { attrs: { start: "", to: { name: "register-new-step4" } } },
        [_vm._v(" Next ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }