var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Important information ")
      ]),
      _c("gov-body", [
        _vm._v(
          "\n    Once your organisation is listed on " +
            _vm._s(_vm.appName) +
            ", it is really important\n    that you keep your organisation and service information up-to-date. For\n    example:\n  "
        )
      ]),
      _c("gov-list", { attrs: { bullet: true } }, [
        _c("li", [_vm._v("opening times")]),
        _c("li", [_vm._v("contact details")]),
        _c("li", [_vm._v("location")]),
        _c("li", [_vm._v("access information")]),
        _c("li", [_vm._v("images and videos")])
      ]),
      _c("gov-body", [
        _vm._v(
          "Keeping these details up-to-date will help make the platform as relevant\n    as possible to local residents."
        )
      ]),
      _c("gov-body", [
        _vm._v(
          "We'll help by emailing you every so often to remind you to check in on\n    your listings."
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }